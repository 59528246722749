body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.__floater {
  filter: none !important;
}
.react-joyride__beacon {
  display: none !important;
}

div.MuiModal-root:has(> div[style*="opacity: 0"]) {
  pointer-events: none;
}

#googledrive-renderer {
  height: 100% !important;
  min-height: 100% !important;
}

#proxy-renderer {
  height: 100% !important;
  min-height: 100% !important;
}
